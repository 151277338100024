html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: normal !important;
}

/**
 * 전역 폰트 설정
 */
* {
  // 네이버 참조
  font-family: "Noto Sans KR", -apple-system,BlinkMacSystemFont,"Malgun Gothic","맑은 고딕",helvetica,"Apple SD Gothic Neo",sans-serif !important;
  box-sizing: border-box;
}

/**
 * 전역 a태그 설정
 */
a {
  text-decoration: none;
}

button {
  span {
    text-transform: initial;
  }
}

// 아임포트
.imp-dialog {
  background-color: rgba(0, 0, 0, .5);
}