@import 'app/base';

/* MUI Css Base Line */
html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

strong,
b {
  font-weight: 700;
}

body {
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  font-size: 0.875rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  background-color: #f4f4f7;
}

@media print {
  body {
    background-color: #fff;
  }
}

body::backdrop {
  background-color: #f4f4f7;
}

/* reset.css */
*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: inherit;
  font-weight: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

input,
textarea {
  -moz-user-select: auto;
  -webkit-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}

input:focus {
  outline: none;
}

button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

*::-webkit-scrollbar {
  width: 1em;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

*::-webkit-scrollbar-thumb {
  background-color: #dfdfe1;
}

// 전역 폰트 설정- regular - 400 / bold - 700
body {
  font-family: Noto Sans KR, -apple-system, BlinkMacSystemFont, Malgun Gothic,
    맑은 고딕, helvetica, Apple SD Gothic Neo, sans-serif !important;
  font-weight: 400;
}

// input 자동완성 배경색 제거
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fff inset;
  -webkit-text-fill-color: #000;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

// print styles
#print-modified {
  display: flex;
  flex-direction: row;
  width: 1550px;
  margin: 0 auto;
}
@media print {
  body {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  #print-modified {
    zoom: 0.7;
    width: 1200px !important;
  }

  #print-modified-hrv {
    zoom: 0.75;
    width: 1400px !important;
  }

  #hrv-item {
    max-width: 600px;
  }

  #translation-select {
    display: none;
  }

  #download-pdf {
    display: none;
  }

  #health-center {
    zoom: 0.6;
  }

  .no-print {
    display: none !important;
  }

  .print-page {
    page-break-after: always;
  }

  .print-page:last-child {
    page-break-after: auto;
  }
}

// date picker
.react-datepicker__input-container {
  input {
    height: 40px;
    border: 1px solid rgba(224, 224, 224, 1);
    border-radius: 4px;
    color: #3c4858;
    padding: 0 8px;
    font-size: 100%;
    width: 100%;
  }
}
.react-datepicker-popper {
  .react-datepicker__month {
    .react-datepicker__month-text {
      height: 40px;
      width: 80px;
      padding-top: 10px;
    }
    .react-datepicker__month-text--keyboard-selected {
      background-color: #0cc7c7;
      color: #fff;
    }
  }
}
.react-datepicker__navigation-icon--previous::before,
.react-datepicker__navigation-icon--next::before {
  top: 12px !important;
}
